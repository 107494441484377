import { render, staticRenderFns } from "./PWAPage.vue?vue&type=template&id=94a9935e&scoped=true&"
import script from "./PWAPage.vue?vue&type=script&lang=js&"
export * from "./PWAPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "94a9935e",
  null
  
)

export default component.exports