<template>
<div class="pwa">

  <div class="field" v-if="landing.profi" style="display: flex;margin-bottom: 15px; align-items: center;" >
    <label class="label" style="margin-right: 15px;margin-bottom: 0;display: block;width: 200px;">{{landing.advanced.global.pwa?'Disable':'Enable'}} PWA</label>
    <v-switch v-model="landing.advanced.global.pwa" @change="addImagesToDropzone" hide-details style="padding: 0;">
    </v-switch>
  </div>
  <div class="field" v-if="landing.advanced.global.pwa&&landing.profi">
        <div>
        <label class="label" style="display:block;font-weight: bold; font-size: 20px;margin-bottom: 15px;">App settings</label>
        <div style="padding:0 15px;">
            <div style="display: flex;align-items: center;margin-bottom: 15px;">
                <label class="label" style="margin-right: 15px;margin-bottom: 0;display: block;width: 200px;">App name</label>
                <v-text-field
                        class="input input_dense"
                        outlined
                        solo
                        flat
                        dense
                        placeholder="Write your app name"
                        v-model="landing.advanced.global.manifest.name"
                        hide-details
                        style="max-width:200px;"
                ></v-text-field>
            </div>
            <div style="display: flex;align-items: center;margin-bottom: 15px;">
                <label class="label" style="margin-right: 15px;margin-bottom: 0;display: block;width: 200px;">App short name</label>
                <v-text-field
                        class="input input_dense"
                        outlined
                        solo
                        flat
                        dense
                        placeholder="Write your app name"
                        v-model="landing.advanced.global.manifest.short_name"
                        hide-details
                        style="max-width:200px;"
                ></v-text-field>
            </div>
            <div style="display: flex;align-items: center;margin-bottom: 15px;">
                <label class="label" style="width: 200px;margin-right: 15px;margin-bottom:0;height: 30px;line-height: 30px;vertical-align: middle;">Theme Color</label>
                <v-menu
                        style="margin-top: 0;"
                        top
                        nudge-left="16"
                        nudge-bottom="98"
                        :close-on-content-click="false"
                        v-model="show_btn_name_color"
                >
                    <template v-slot:activator="{ on }">
                        <div class="colorpicker" :style="`background-color:${landing.advanced.global.manifest.theme_color}`" v-on="on" />
                    </template>
                    <v-card>
                        <v-card-text class="pa-0">
                            <v-color-picker v-model="landing.advanced.global.manifest.theme_color" @input="show_btn_name_color = false" flat/>
                        </v-card-text>
                    </v-card>
                </v-menu>
            </div>
            <div style="display: flex;align-items: center;margin-bottom: 15px;">
                <label class="label" style="width: 200px;margin-right: 15px;margin-bottom:0;height: 30px;line-height: 30px;vertical-align: middle;">Background Color</label>
                <v-menu
                        style="margin-top: 0;"
                        top
                        nudge-left="16"
                        nudge-bottom="98"
                        :close-on-content-click="false"
                        v-model="show_btn_name_color2"
                >
                    <template v-slot:activator="{ on }">
                        <div class="colorpicker" :style="`background-color:${landing.advanced.global.manifest.background_color}`" v-on="on" />
                    </template>
                    <v-card>
                        <v-card-text class="pa-0">
                            <v-color-picker v-model="landing.advanced.global.manifest.background_color" @input="show_btn_name_color2 = false" flat/>
                        </v-card-text>
                    </v-card>
                </v-menu>
            </div>
            <div style="margin-bottom: 15px;">
                <label class="label" style="display:block;margin-bottom: 15px;">App icon 192x192</label>
                <vue-dropzone
                        id="upload"
                        style="max-width: 200px;"
                        ref="upload"
                        :options="dropzoneOptions"
                        :destroyDropzone="false"
                        @vdropzone-sending="customEvent"
                        @vdropzone-removed-file="handleRemove"
                        @vdropzone-success="handleSuccess"
                        @vdropzone-max-files-exceeded="logoMaxFileSexceeded"
                        @vdropzone-mounted="mountLittle"
                />
            </div>
            <div style="margin-bottom: 15px;">
                <label class="label" style="display:block;margin-bottom: 15px;">App icon 512x512</label>
                <vue-dropzone
                        id="uploadBig"
                        style="max-width: 200px;"
                        ref="uploadBig"
                        :options="dropzoneOptions"
                        :destroyDropzone="false"
                        @vdropzone-sending="customEvent"
                        @vdropzone-removed-file="handleRemoveBig"
                        @vdropzone-mounted="mountBig"
                        @vdropzone-success="handleSuccessBig"
                        @vdropzone-max-files-exceeded="logoMaxFileSexceededBig"
                />
            </div>
        </div>
    </div>
      <div>
          <label class="label" style="display:block;font-weight: bold; font-size: 20px;margin-bottom: 15px;">Install settings</label>
          <div style="padding: 0 15px;">
              <div style="display: flex;margin-bottom: 15px; align-items: center;" >
                  <label class="label" style="margin-right: 15px;margin-bottom: 0;display: block;width: 200px;">{{active_install_settings?'Enable':'Disable'}} install settings</label>
                  <v-switch v-model="active_install_settings" @change="changeInstallSettings" hide-details style="padding: 0;">
                  </v-switch>
              </div>

              <v-tabs

                      v-model="activeLang"
              >
                  <v-tab
                          v-for="(lang, i) in landing.selected_langs"
                          :key="i"
                          @change="changeLang(lang)"
                  >
                      <img
                              :src="landing.advanced.langs.find((x) => x.value === lang).flag"
                              alt=""
                              style="width: 20px; height: 15px; margin-right: 5px"
                      />
                      {{ lang }}
                  </v-tab>
                  <v-tab-item
                          style="padding-top: 15px"
                          v-for="(lang, i) in landing.selected_langs"
                          :key="i"
                  >

                      <div style="display: flex;align-items: center;margin-bottom: 15px;">
                          <label class="label" style="margin-right: 15px;margin-bottom: 0;display: block;width: 200px;">Title</label>
                          <v-text-field
                                  class="input input_dense"
                                  outlined
                                  solo
                                  flat
                                  dense
                                  placeholder="Write your title"
                                  v-model="$store.state.advanced.install_settings.title[lang]"
                                  hide-details
                                  style="max-width:200px;"
                          ></v-text-field>
                      </div>
                      <div style="display: flex;align-items: center;margin-bottom: 15px;">
                          <label class="label" style="margin-right: 15px;margin-bottom: 0;display: block;width: 200px;">Description</label>
                          <v-text-field
                                  class="input input_dense"
                                  outlined
                                  solo
                                  flat
                                  dense
                                  placeholder="Write your description"
                                  v-model="$store.state.advanced.install_settings.description[lang]"
                                  hide-details
                                  style="max-width:200px;"
                          ></v-text-field>
                      </div>
                      <div style="display: flex;align-items: center;margin-bottom: 15px;">
                          <label class="label" style="margin-right: 15px;margin-bottom: 0;display: block;width: 200px;">Button text</label>
                          <v-text-field
                                  class="input input_dense"
                                  outlined
                                  solo
                                  flat
                                  dense
                                  placeholder="Write your button text"
                                  v-model="$store.state.advanced.install_settings.button[lang]"
                                  hide-details
                                  style="max-width:200px;"
                          ></v-text-field>
                      </div>
                  </v-tab-item>
              </v-tabs>
          </div>
      </div>
  </div>

  <div style="display: flex;padding:20px 0;" v-if="!landing.profi">
    <v-icon color="#cccccc" size="60px" style="margin-right: 20px;">mdi-lock</v-icon>
    <h1 class="constructor__heading" style="font-weight: normal;font-size: 16px;">This feature is available on the Pro plans.
      <br>To activate the tariff <a @click="billing">click here</a></h1>
  </div>
  <PopupTariff ref="PopupTariff"/>
</div>
</template>

<script>
  import "vue2-dropzone/dist/vue2Dropzone.min.css";
  import {mapState} from "vuex";
  import HotelService from "../../../services/hotel.service";
  import PWAService from "../../../services/pwa.service";

  export default {
  name: "PWAPage",
  components:{
    PopupTariff: () => import("../../Popup/PopupTariff"),
    vueDropzone:() => import("vue2-dropzone"),
  },
  data(){
    return{
      active_install_settings:true,
      show:false,
      pwa_name:"App Name",
      pwa_name_short:"App",
      size_small:null,
      size_medium:null,
      size_large:null,
      show_btn_name_color:false,
      show_btn_name_color2:false,
      theme:"#ffffff",
      background:"#ffdd2d",
      dropzoneOptions: {
        url: () => {
          return process.env.VUE_APP_API_URL + "upload-image";
        },
        thumbnailMethod: "contain",
        acceptedFiles: "image/*",
        addRemoveLinks: true,
        maxFilesize: 3,
        maxFiles: 1,
        destroyDropzone: false,
      },
    }
  },
  computed:{
    ...mapState(["landing"]),
    activeLang:{
      set:function(val){

      },
      get:function(){
        return this.landing.advanced.selected_langs.indexOf(this.landing.current_lang)
      }
    },
  },
  async mounted() {
    await this.getInstallSettings();
    if(!this.$store.state.advanced.install_settings.hotel_id){
      this.$store.state.advanced.install_settings.hotel_id = this.$route.params.id;
    }
    if(!this.$store.state.advanced.install_settings.image){
      this.$store.state.advanced.install_settings.image = this.landing.advanced.global.manifest.icons[0].src;
    }
  },
  methods:{
    async getInstallSettings(){
      const data =  await PWAService.getBannerPWAByHotelID(this.$route.params.id);
      if(data.id){
        this.$store.state.advanced.install_settings = data;
      }
    },
    async postInstallSettings(){
      this.$store.state.advanced.install_settings = await PWAService.postBannerPWA(this.$store.state.advanced.install_settings);
    },
    async putInstallSettings(){
      this.$store.state.advanced.install_settings = await PWAService.putBannerPWA(this.$store.state.advanced.install_settings);
    },
    changeLang(lang) {
      this.$store.commit("landing/setCurrentLang", lang);
    },
    changeInstallSettings(){
      if(this.active_install_settings){
        this.$store.state.advanced.install_settings.is_active = 1;
      }else{
        this.$store.state.advanced.install_settings.is_active = 0;
      }
    },
    mountLittle(){
      let mockFile = {
        accepted: true,
        kind: "image",
        ...this.landing.advanced.global.manifest.icons[0],
      };
      if(!this.$refs.upload.dropzone.files.length){
        this.$refs.upload.manuallyAddFile(mockFile,  this.landing.advanced.global.manifest.icons[0].src);
        this.$refs.upload.dropzone.emit("thumbnail", mockFile, this.landing.advanced.global.manifest.icons[0].src);
        this.$refs.upload.dropzone.emit("complete", mockFile);
      }
    },
    mountBig(){
      let mockFile1 = {
        accepted: true,
        kind: "image",
        ...this.landing.advanced.global.manifest.icons[1],
      };
      if(!this.$refs.uploadBig.dropzone.files.length){
        this.$refs.uploadBig.manuallyAddFile(mockFile1,  this.landing.advanced.global.manifest.icons[1].src);
        this.$refs.uploadBig.dropzone.emit("thumbnail", mockFile1, this.landing.advanced.global.manifest.icons[1].src);
        this.$refs.uploadBig.dropzone.emit("complete", mockFile1);
      }
    },
    billing(){
      let obj = this.$store.state.objects.list.find(x => +x.id === +this.$route.params.id);
      this.$refs.PopupTariff.open(obj);
    },
    async addImagesToDropzone(){
      if(this.landing.advanced.global.pwa){
        await HotelService.togglePwa(this.$route.params.id);
        if(!this.$store.state.advanced.install_settings.id){
          await this.postInstallSettings();
        }
      }else{
        await HotelService.togglePwa(this.$route.params.id);
      }
    },
    async customEvent(_file, xhr, formData) {
      xhr.setRequestHeader(
          "Authorization",
          `Bearer ${this.$store.state.token}`
      );
      formData.append(
          "lending_settings_id",
          this.landing.settings_id
      );
      formData.append('image',_file);
    },
    logoMaxFileSexceeded(file) {
      this.$refs.upload.removeAllFiles();
      this.$refs.upload.addFile(file);
    },
    handleSuccess(file) {
      this.$store.state.advanced.install_settings.image = file.xhr.response;
      this.landing.advanced.global.manifest.icons[0].src = file.xhr.response;
    },
    async handleRemove() {
      this.landing.advanced.global.manifest.icons[0].src = null;
    },
    logoMaxFileSexceededBig(file) {
      this.$refs.uploadBig.removeAllFiles();
      this.$refs.uploadBig.addFile(file);
    },
    handleSuccessBig(file) {
      this.landing.advanced.global.manifest.icons[1].src = file.xhr.response;
    },
    async handleRemoveBig() {
      this.landing.advanced.global.manifest.icons[1].src = null;
    },
  }
}
</script>

<style scoped lang="scss">

</style>
